<template>
  <b-card class="pa-5 m-0">
    <h4>{{ title }}</h4>

    <div class="mt-10 flex-row  ">
      <b-form-select
        v-if="selectDomain"
        v-model="domainSelected"
        :options="selectDomain.itemsSelect"
        :text-field="selectDomain.selectLabel"
        :value-field="selectDomain.selectValue"
        @change="changeDomain"
        :disabled="isLoading"
        class="mr-5"
      >
        <template #first>
          <b-form-select-option :value="null"
            >Choose a domain
          </b-form-select-option>
        </template>
      </b-form-select>

      <b-form-select
        v-if="selectProgram"
        v-model="programSelected"
        :options="selectProgram.itemsSelect"
        :text-field="selectProgram.selectLabel"
        :value-field="selectProgram.selectValue"
        @change="changeProgram"
        :disabled="isLoading"
        class="mr-5"
      >
        <template #first>
          <b-form-select-option :value="null"
            >Choose a program
          </b-form-select-option>
        </template>
      </b-form-select>
    </div>

    <div class="mt-5" style="height: 19px">
      <template>
        <span class="font-bold"
          ><strong
            >{{ items.length }} / {{ pagination.total_items }} results</strong
          ></span
        >
      </template>
    </div>

    <Datatable
      :header="headers"
      :items="items"
      :isLoading="isLoading"
      :pagination="pagination"
    />
  </b-card>
</template>

<script>
import Datatable from "@/components/campaignV2/Datatable";

export default {
  components: {
    Datatable
  },

  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    isSearch: {
      type: Boolean,
      required: true
    },
    selectDomain: {
      type: Object,
      require: false,
      default: () => {}
    },
    selectProgram: {
      type: Object,
      require: false,
      default: () => {}
    }
  },

  data() {
    return {
      domainSelected: null,
      programSelected: null
    };
  },

  methods: {
    changeDomain(domain) {
      this.$emit("changeDomain", domain);
    },

    changeProgram(program) {
      this.$emit("changeProgram", program);
    }
  }
};
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
}
</style>
