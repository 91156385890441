export const inputFilters = [
  {
    name: "campaigns",
    label: "All Campaigns",
    valueField: "externalId",
    elasticSearchFilterField: "campaigns"
  },
  {
    name: "devices",
    label: "All Devices",
    elasticSearchFilterField: "device",
    displayText: "name",
    valueField: "value",
    isSearchBack: false
  },
  {
    name: "modes",
    label: "All Modes",
    elasticSearchFilterField: "mode",
    displayText: "name",
    valueField: "value",
    isSearchBack: false
  }
];
