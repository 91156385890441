<template>
  <div>
    <b-table
      class="bb-datatable"
      striped
      hover
      borderless
      select-mode="single"
      :fields="header"
      :items="items"
      responsive
      :busy="isLoading"
      thead-class="header-color"
      :current-page="currentPage"
      show-empty
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-3">{{ $tc("DATATABLE.LOADING") }}...</strong>
        </div>
      </template>

      <template #empty>
        <div class="d-flex justify-content-center">
          <span class="font-weight-bold">{{ $tc("DATATABLE.NO_DATA") }}</span>
        </div>
      </template>

      <template #thead-top="data">
        <b-tr>
          <b-th colspan="5"></b-th>
          <b-th colspan="3" class="bg-secondary text-center border"
            >BUDGET</b-th
          >
          <b-th colspan="4" class="bg-secondary text-center border"
            >LISTENS</b-th
          >
        </b-tr>
      </template>

      <template #cell(name)="data">
        <span class="mr-2"
          ><strong>{{ data.item.name }} </strong>
          <v-tooltip v-if="data.item.isAuthorizedAds" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark v-bind="attrs" v-on="on">
                mdi-currency-usd
              </v-icon>
            </template>
            <span>Ads authorized</span>
          </v-tooltip>
        </span>
      </template>

      <template #cell(campaign_name)="data">
        <span class="mr-3">
          <v-tooltip top v-if="data.item.containArtificialBudgetRatio > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="containArtificialBudgetRatio(data.item).color"
                dark
                v-bind="attrs"
                v-on="on"
              >
                {{ containArtificialBudgetRatio(data.item).icon }}
              </v-icon>
            </template>
            <span>{{
              containArtificialBudgetRatio(data.item).tooltipLabel
            }}</span>
          </v-tooltip>
          <strong>&nbsp;{{ data.item.name }} </strong>

          <v-tooltip top v-if="data.item.delivery_type === 'smooth'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="getPriority(data.item).color"
                dark
                v-bind="attrs"
                v-on="on"
              >
                {{ getPriority(data.item).icon }}
              </v-icon>
            </template>
            <span>{{ getPriority(data.item).tooltipLabel }}</span>
          </v-tooltip>
        </span>
      </template>

      <template #cell(minDate)="data">
        <span>{{
          data.item.minDate ? $d(new Date(data.item.minDate), "short") : ""
        }}</span>
      </template>

      <template #cell(maxDate)="data">
        <span>{{
          data.item.maxDate ? $d(new Date(data.item.maxDate), "short") : ""
        }}</span>
      </template>

      <template #cell(programName)="data">
        <span
          ><strong>{{ data.item.programName }}</strong></span
        ><br />
        <span class="sub-data"
          >{{ data.item.nbrPrograms }} {{ $tc("DATATABLE.PROGRAMS") }}</span
        >
      </template>

      <template #cell(status)="data">
        <span
          v-if="data.item.status"
          class="label label-lg label-inline text-capitalize"
          :class="data.item.status.class"
          >{{ data.item.status.name }}</span
        >
        <span v-else>-</span>
      </template>

      <template #cell(sum_inventory)="data">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"
              >{{ data.item.inventory_click + data.item.inventory_next
              }}<v-icon class="ml-3">mdi-information</v-icon></span
            >
          </template>
          <span
            >Inventory click: {{ data.item.inventory_click }} / Inventory next:
            {{ data.item.inventory_next }}</span
          >
        </v-tooltip>
      </template>

      <template #cell(fill_rating)="data">
        <span>
          {{
            (
              (data.item.sponsored_play /
                (data.item.inventory_click + data.item.inventory_next)) *
                100 || 0
            ).toFixed(2)
          }}
          %</span
        >
      </template>

      <template #cell(pacing)="data">
        <span
          class="label label-lg label-inline font-weight-bold text-center"
          :class="'label-light-' + getPacingStatut(data.item).color"
        >
          {{ data.item.pacing ? data.item.pacing + " %" : "" }}
        </span>
      </template>

      <template #cell(budget)="data">
        <span>{{ data.item.budget ? data.item.budget + " €" : "" }}</span>
      </template>

      <template #cell(cpl)="data">
        <span>{{ data.item.cpl ? data.item.cpl + " €" : "" }}</span>
      </template>

      <template #cell(spent)="data">
        <span>{{ data.item.spent ? data.item.spent + " €" : "" }}</span>
      </template>

      <template #cell(total_listens_to_deliver)="data">
        <span>{{ data.item.listensGoal }}</span>
      </template>

      <template #cell(delivered_listens)="data">
        <span>{{ data.item["paid:plays"] }}</span>
      </template>

      <template #cell(remaining_listens)="data">
        <span>{{ data.item.listenLeft }}</span>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="pagination.total_items"
      :per-page="pagination.items_per_page"
      aria-controls="my-table"
      @change="changePage"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean
    }
  },

  data() {
    return {
      currentPage: 1
    };
  },

  methods: {
    changePage(page) {
      this.$parent.$emit("changePage", page);
    },

    getPriority(item) {
      let priority = {
        color: "primary",
        icon: "",
        tooltipLabel: "Delivery mode: "
      };

      switch (item.priority) {
        case 5:
          priority.color = "orange";
          priority.icon = "mdi-chevron-up";
          priority.tooltipLabel += "High";
          break;
        case 10:
          priority.color = "red";
          priority.icon = "mdi-chevron-double-up";
          priority.tooltipLabel += "Highest";
          break;
      }

      return priority;
    },

    getPacingStatut(item) {
      if (item.pacing == null) {
        return {
          color: "light"
        };
      }
      if (item.pacing < 0) {
        return {
          color: "danger"
        };
      } else {
        return {
          color: "success"
        };
      }
    },

    containArtificialBudgetRatio(item) {
      const containArtificialBudgetRatio = item.containArtificialBudgetRatio;
      const defaultProperties = {
        icon: "mdi-speedometer-medium",
        tooltipLabel: `Artificial Budget Ratio`
      };

      if (containArtificialBudgetRatio > 0) {
        return {
          ...defaultProperties,
          color: "orange"
        };
      }

      return {
        icon: "-",
        tooltipLabel: "",
        color: "black"
      };
    }
  }
};
</script>

<style lang="scss">
.header-color,
.table thead th,
thead,
th {
  color: #b5b5c3;
  font-size: 12;
  font-weight: bold;
}

.sub-data {
  color: #7e8299;
}

.bb-datatable .table-hover tbody tr:hover {
  background: #e8e8e8;
}
</style>
