import { renderSeconds } from "@/common/functions";

const normalizeExportData = periodDatas => {
  delete periodDatas["total"];
  delete periodDatas["debugQuery"];
  let results = [];

  for (var key in periodDatas) {
    results.push({
      date: key,
      listens: periodDatas[key]["plays:paid"] || 0,
      budgetsspent: periodDatas[key]["budget:spent"] || 0,
      atimelistenings: periodDatas[key]["average:timelistenings"] || 0,
      ttimelistenings: periodDatas[key]["total:timelistenings"] || 0,
      ecostperlisten: periodDatas[key]["ecost"] || 0
    });
  }
  return results;
};

const normalizeCampaignsDataTable = periodDatas => {
  delete periodDatas["total"];
  delete periodDatas["debugQuery"];
  let results = [];

  for (var key in periodDatas) {
    results.push({
      date: key,
      listens: periodDatas[key]["plays:paid"] || 0,
      budgetsspent: periodDatas[key]["budgetsspent"] || 0,
      atimelistenings: renderSeconds(
        periodDatas[key]["average:timelistenings"] || 0
      ),
      ttimelistenings: renderSeconds(
        periodDatas[key]["total:timelistenings"] || 0
      ),
      ecostperlisten: periodDatas[key]["ecost"] || 0
    });
  }
  return results;
};

export const tableFields = [
  {
    key: "name",
    label: "Name",
    sortable: true,
    sortDirection: "desc",
    class: "align-middle"
  },
  {
    key: "priority",
    label: "Priority",
    sortable: true,
    sortDirection: "desc",
    class: "align-middle",
    role: "admin"
  },
  {
    key: "company.name",
    label: "Company",
    sortable: true,
    sortDirection: "desc",
    class: "align-middle",
    role: "admin"
  },
  {
    key: "startAt",
    label: "Start",
    sortable: true,
    sortDirection: "desc",
    class: "text-center align-middle"
  },
  {
    key: "endAt",
    label: "End",
    sortable: true,
    sortDirection: "desc",
    class: "text-center align-middle"
  },
  {
    key: "budget",
    label: "Budget Global",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-right align-middle",
    formatter: (value, key, item) => {
      return item.budget ? item.budget + "€" : "";
    }
  },
  {
    key: "budgetspent",
    label: "Spend",
    sortable: false,
    sortDirection: "desc",
    class: "text-center align-middle"
  },
  {
    key: "playspaid",
    label: "Paid plays",
    sortable: false,
    sortDirection: "desc",
    class: "text-right align-middle"
  },
  {
    key: "averagetimelistenings",
    label: "AVG. LIST. time",
    sortable: false,
    sortDirection: "desc",
    class: "text-right align-middle"
  },
  {
    key: "totaltimelistenings",
    label: "Total. LIST. time",
    sortable: false,
    sortDirection: "desc",
    class: "text-right align-middle"
  },
  {
    key: "ecost",
    label: "CPL",
    sortable: false,
    sortDirection: "desc",
    class: "text-right align-middle"
  },
  {
    key: "statutOrder",
    label: "Status",
    sortable: true,
    sortDirection: "desc",
    class: "text-center align-middle"
  },
  {
    key: "actions",
    label: "Actions",
    sortable: false,
    sortDirection: "desc",
    class: "text-right align-middle p-0"
  }
];

export const tabsData = [
  {
    loading: true,
    title: "Spent",
    chartTitle: "Spend",
    primaryStat: "NaN",
    previousCompare: "NaN",
    iconSVG: "assets/media/icons/svg/Shopping/Credit-card.svg",
    iconSVGColor: "info",
    theme: "light",
    value: "budget:spent",
    selected: true,
    normalizeDataTable: function(datas) {
      return normalizeCampaignsDataTable(datas);
    },
    normalizeExportData: function(datas) {
      return normalizeExportData(datas);
    },
    renderFigure: function(x) {
      return x + "€";
    },
    chartDataToDisplay: [
      {
        value: "budget:spent",
        color: "#FF961D",
        label: "Paid"
      }
    ]
  },
  {
    loading: true,
    title: "Listens",
    primaryStat: "NaN",
    previousCompare: "NaN",
    iconSVG: "assets/media/icons/svg/Media/Play3.svg",
    iconSVGColor: "primary",
    cardClass: "bg-pi",
    value: "plays:paid",
    selected: false,
    normalizeDataTable: function(datas) {
      return normalizeCampaignsDataTable(datas);
    },
    normalizeExportData: function(datas) {
      return normalizeExportData(datas);
    },
    chartDataToDisplay: [
      {
        value: "plays:paid",
        color: "#FF961D",
        label: "Organic"
      }
    ]
  },
  {
    loading: true,
    title: "Average listening time",
    primaryStat: "NaN",
    previousCompare: "NaN",
    iconSVG: "assets/media/icons/svg/Home/Timer.svg",
    iconSVGColor: "warning",
    theme: "light",
    value: "average:timelistenings",
    selected: false,
    normalizeDataTable: function(datas) {
      return normalizeCampaignsDataTable(datas);
    },
    renderFigure: function(x) {
      return renderSeconds(x);
    },
    isTimeChart: true,
    chartDataToDisplay: [
      {
        value: "average:timelistenings",
        color: "#FF961D",
        label: "Paid"
      }
    ]
  },
  {
    loading: true,
    title: "Total listening time",
    primaryStat: "NaN",
    previousCompare: "NaN",
    iconSVG: "assets/media/icons/svg/Home/Timer2.svg",
    iconSVGColor: "success",
    theme: "light",
    value: "total:timelistenings",
    selected: false,
    normalizeDataTable: function(datas) {
      return normalizeCampaignsDataTable(datas);
    },
    renderFigure: function(x) {
      return renderSeconds(x);
    },
    isTimeChart: true,
    chartDataToDisplay: [
      {
        value: "total:timelistenings",
        color: "#FF961D",
        label: "Paid"
      }
    ]
  },
  {
    loading: true,
    title: "eCost per listen",
    primaryStat: "NaN",
    previousCompare: "NaN",
    iconSVG: "assets/media/icons/svg/Shopping/PlayEuro.svg",
    iconSVGColor: "dark",
    theme: "light",
    value: "ecost",
    selected: false,
    normalizeDataTable: function(datas) {
      return normalizeCampaignsDataTable(datas);
    },
    renderFigure: function(x) {
      return x + "€";
    },
    chartDataToDisplay: [
      {
        value: "ecost",
        color: "#FF961D",
        label: "Paid"
      }
    ]
  }
];
