import i18n from "@/common/plugins/vue-i18n";

export const headers = [
  {
    label: i18n.t("BROADCAST.NAME"),
    key: "campaign_name",
    sortable: true
  },
  {
    label: i18n.t("BROADCAST.STATUS"),
    key: "status",
    sortable: true
  },
  {
    label: i18n.t("BROADCAST.START_DATE"),
    key: "minDate",
    sortable: true
  },
  {
    label: i18n.t("BROADCAST.END_DATE"),
    key: "maxDate",
    sortable: true
  },
  {
    label: i18n.t("BROADCAST.PACING"),
    key: "pacing",
    sortable: true,
    thStyle: { width: "100px" }
  },
  {
    label: i18n.t("BROADCAST.BUDGET"),
    key: "budget",
    sortable: true
  },
  {
    label: i18n.t("BROADCAST.COST_PER_LISTEN"),
    key: "cpl",
    sortable: true
  },
  {
    label: i18n.t("BROADCAST.SPENT"),
    key: "spent",
    sortable: true
  },
  {
    label: i18n.t("BROADCAST.TOTAL_LISTENS_DELIVER"),
    key: "total_listens_to_deliver",
    sortable: true
  },
  {
    label: i18n.t("BROADCAST.DELIVERED_LISTENS"),
    key: "delivered_listens",
    sortable: true
  },
  {
    label: i18n.t("BROADCAST.REMAINING_LISTENS"),
    key: "remaining_listens",
    sortable: true
  },
  {
    label: i18n.t("BROADCAST.LISTENS_DELIVER"),
    key: "listens_to_deliver",
    sortable: true
  }
];
