<template>
  <div>
    <FiltersTemplate
      v-if="isAdmin"
      :inputFilters="inputFilters"
      apiSlug="sponsorings"
    />

    <AlertExpirePassword />

    <StatsPage
      class="kt-container"
      v-bind:displayCreate="true"
      v-bind:APIslug="'sponsorings'"
      v-bind:tabsData="tabsData"
      v-bind:displayChart="false"
      v-bind:inputFilters="inputFilters"
    >
    </StatsPage>

    <br />

    <div class="kt-container">
      <CardDatatable
        :headers="headers"
        :items="items"
        :pagination="pagination"
        title="Native Ad Campaigns"
        :displayPerpage="20"
        :isSearch="false"
        :isLoading="isLoading"
        @changePage="changePage"
      />
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import CardDatatable from "@/components/campaignV2/CardDatatable";
import { headers } from "@/common/config/campaigns/broadcast/menuV2.config";
import AlertExpirePassword from "@/components/AlertExpirePassword";
import {
  getCampaignsV2,
  getCampaignsV2Stats
} from "@/api/campaigns/manage/getters";
import { getStatusCampaign } from "@/common/functions";
import StatsPage from "@/components/layout/StatsPage";
import FiltersTemplate from "@/components/subheaders/filters/FiltersTemplate";
import { tabsData } from "@/common/config/dashboard/sponsoring";
import { inputFilters } from "@/common/config/filters/sponsoringList";

export default {
  components: {
    CardDatatable,
    AlertExpirePassword,
    StatsPage,
    FiltersTemplate
  },

  data() {
    return {
      isLoading: false,
      items: [],
      pagination: {
        total_items: 0,
        items_per_page: 10,
        current_page: 1
      },
      headers: headers,
      filters: {
        start_date: "",
        end_date: "",
        q: "",
        page: 1
      },
      timeout: 25000,
      timeoutSearch: null,
      startDate: null,
      endDate: null,
      status: null,
      loading: false,
      tabsData: tabsData,
      searchFilter: "",
      inputFilters: inputFilters
    };
  },

  computed: {
    ...mapGetters({
      dateRange: "dateRange",
      isAdmin: "isAdmin",
      isPaidCustomer: "isPaidCustomer"
    }),
    filteredFields() {
      return this.fields.map(field => {
        if (field.role == "admin") {
          if (this.isAdmin) {
            return field;
          } else {
            return false;
          }
        }
        return field;
      });
    }
  },

  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Broadcast",
        pageCat: "Analytics"
      }
    ]);

    setTimeout(() => {
      this.loadCampaigns();
    }, 500);
  },
  mounted() {
    // generate dates
    this.endDate = new Date();
    this.startDate = new Date();
    this.startDate.setDate(this.endDate.getDate() - 1);
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.filters.page = this.currentPage;
      this.filters.q = this.searchFilter;
      this.loadCampaigns();
    },

    async loadCampaigns() {
      this.isLoading = true;
      let today = new Date().toJSON().slice(0, 10);
      this.filters.start_date = today;
      this.filters.end_date = today;

      try {
        const response = await getCampaignsV2(this.axios, this.filters);
        this.items = response.data || [];
        this.pagination = {
          total_items: response.total_items,
          items_per_page: response.items_per_page,
          current_page: response.current_page
        };

        if (this.items.length > 0) {
          await this.loadStats();
        }

        this.isLoading = false;
      } catch (error) {
        console.error("---ERROR-CAMPAIGNS-V2---");
        console.error(error);
      }
    },

    async loadStats() {
      let campaignsId = [];

      this.items.forEach(element => {
        element.statsLoading = true;
        campaignsId.push(element.id);
      });

      let copyItems = [...this.items];

      try {
        const response = await getCampaignsV2Stats(
          { campaigns: campaignsId },
          this.axios
        );

        copyItems.forEach(element => {
          const statsItems = response[element.id];
          Object.assign(element, statsItems);
          if (element.minDate && element.maxDate) {
            this.setStatusForCampaign(element);
          }
        });
      } catch (error) {
        console.error("---ERROR-STATS-CAMPAINGS");
        console.error(error);
      }

      copyItems.forEach(element => {
        element.statsLoading = false;
      });

      this.items = copyItems;

      this.isLoading = false;
    },

    setStatusForCampaign(item) {
      return getStatusCampaign(item);
    }
  }
};
</script>
